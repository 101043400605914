<template>
  <div class="home">
    <Section light>
      <h1>Electrical</h1>
      <v-col class="px-4 mt-4 col-md-6 col-12">
        <p>
          As a competition where you only have one chance per year at each
          mission, the most important requirement of the rover's electrical
          systems is that they be as reliable as possible. Since we have the
          experience of building functional electrical systems for our past
          rovers, the electrical team spends most of its time in a
          brainstorming, design, manufacturing, and testing cycle to implement
          new features and fixes for the rover. This allows continuous
          improvement while maintaining uptime to allow for testing by the other
          subteams.Using this methodology, we have implemented many systems on
          the rover which we are proud of.
        </p>
      </v-col>
      <v-col class="px-4 mt-4 col-md-6 col-12">
        <v-img
          class="shrink mx-auto"
          contain
          :src="require('@/assets/electrical/generic.jpg')"
          max-height="500px"
        />
      </v-col>
      <v-col cols="12">
        <h2>Wireless</h2>
      </v-col>
      <v-col class="px-4 mt-4 col-md-6 col-12">
        <v-img
          class="shrink mx-auto"
          contain
          :src="require('@/assets/electrical/driving_sm.gif')"
          max-height="500px"
        />
      </v-col>
      <v-col class="px-4 mt-4 col-md-6 col-12">
        <p>
          Our high-speed 5.8 GHz wireless link to the rover is robust enough to
          remain fully functional at distances of over 1 kilometer, yet fast
          enough to allow low-latency, high quality video feeds to be streamed
          from the rover.
        </p>
      </v-col>
      <v-col cols="12">
        <h2>Arm</h2>
      </v-col>
      <v-col class="px-4 mt-4 col-md-6 col-12">
        <p>
          The electrical systems for our arm are packed into a small enclosure
          which attaches to the base of the arm, allowing for the arm to be
          tested using just a battery and a laptop. This level of compactness
          was only possible through thorough 3D modeling and PCB design.
        </p>
      </v-col>
      <v-col class="px-4 mt-4 col-md-6 col-12">
        <v-img
          class="shrink mx-auto"
          contain
          :src="require('@/assets/electrical/arm.jpg')"
          max-height="500px"
        />
      </v-col>
      <v-col cols="12">
        <h2>Ongoing + Upcoming Projects</h2>
      </v-col>
      <v-col class="px-4 mt-4 col-md-6 col-12">
        <v-img
          class="shrink mx-auto"
          contain
          :src="require('@/assets/electrical/wireless.png')"
          max-height="500px"
        />
      </v-col>
      <v-col class="px-4 mt-4 col-md-6 col-12">
        <p>
          In the coming year, we have a lot of projects planned to continue
          upgrading our rover for an even better performance at the 2024 URC.
          With our mechanical redesign of the arm, we hope to integrate its
          electronics into the whole arm for a more organized system. This will
          involve industry-like collaboration between the electrical and
          mechanical subteams to fully design and model the chassis and PCBs to
          ensure both its mechanical and electrical requirements are met.
        </p>
      </v-col>
      <v-col class="px-4 mt-4 col-md-6 col-12">
        <p>
          Continuous self-sensing and monitoring are key to a robust electrical
          system, allowing for problems to be quickly identified or avoided
          altogether. In the coming year, we will begin to adopt temperature
          sensors, current sensors, and redundant position sensing across our
          robot to further reduce the chances of any critical failures while
          performing at the URC.
        </p>
      </v-col>
      <v-col class="px-4 mt-4 col-md-6 col-12">
        <v-img
          class="shrink mx-auto"
          contain
          :src="require('@/assets/electrical/sensing.jpg')"
          max-height="500px"
        />
      </v-col>
    </Section>
  </div>
</template>

<script>
import Section from '@/components/Section.vue';

export default {
  name: 'Mechanical',
  components: {
    Section,
  },
};
</script>
<style scoped>
h2 {
  text-align: center;
}
</style>
